import React from 'react';
import * as S from './Presentation.style';
import geegeeHero from './geegee-hero-image.png';
import logo from './logo-dark.png';
import Contact from '../../components/Contact';
import useScreen from '../../hooks/useScreen';

const Presentation: React.FC = () => {
  const { isMobile, isTablet, isDesktop } = useScreen();
  const sizeLogo = () => {
    if (isDesktop)
      return {
        presentationImg: { width: 200, height: 500 },
        logo: { width: 200 },
      };
    if (isTablet)
      return {
        presentationImg: { width: 100, height: 300 },
        logo: { width: 130 },
      };
    if (isMobile)
      return {
        presentationImg: { width: 50, height: 250 },
        logo: { width: 100 },
      };
  };

  return (
    <S.PresentationContainer>
      <S.Menu>
        <S.Wrapper className="logo">
          <img src={logo} width={sizeLogo()?.logo.width || 200} />
        </S.Wrapper>
      </S.Menu>
      <S.Wrapper>
        <S.Container>
          <S.TitleContainer>
            <S.Title>Ads should be fun!</S.Title>
            <S.Description>
              The first platform that use games and virtual worlds in place of
              traditional ads
            </S.Description>
            <div style={{ marginTop: 24 }}>
              <Contact />
            </div>
          </S.TitleContainer>
          <S.Img>
            <img src={geegeeHero} height={sizeLogo()?.presentationImg.height} />
          </S.Img>
        </S.Container>
      </S.Wrapper>
    </S.PresentationContainer>
  );
};

export default Presentation;
